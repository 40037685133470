import request from '@/utils/request2';
import md5 from 'js-md5'
// import qs from 'qs'
function getSign(params) {
  if (!params) {
    params = {};
  }
  
  params.ct = Date.now();
  params.jpressAppId = 'admin';
  const secret = '123456';
  const keys = Object.keys(params);
  keys.sort();
  let str = '';
  for (const i of keys) {
    str += i + params[i];
  }
  
  str += secret;
  
  let sign = md5( window.encodeURI(str)); 
  params.sign = sign;
  return params;

}

// 获取新闻列表
export function newsListAPI(params) {
  // console.log(params)
  let newParams = getSign(params);
  return request({
    url: '/article/paginate',
    method: 'get',
    params: newParams
  })
}
// 获取新闻详情
export function newsDetail(params) {
  const newParams = getSign(params);
  // console.log(newParams)
  return request({
    url: '/article/detail',
    method: 'get',
    params: newParams
  })
}
// 获取热门新闻
export function newsHot(params) {
  const newParams = getSign(params);
  // console.log(newParams)
  return request({
    url: '/article/findAllCategoryByWithHot',
    method: 'get',
    params: newParams
  })
}
// 搜索文章
export function getArticle(params) {
  const newParams = getSign(params);
  // console.log(newParams)
  return request({
    url: '/article/search',
    method: 'get',
    params: newParams
  })
}